import React, { useState } from 'react';
import '../../../Styles/Productos.css';
import BCC0HZF_1 from "../../../images/Redes/BCC0HZF-1.png";


function Producto1() {
    const [selectedImage, setSelectedImage] = useState(BCC0HZF_1);
    const [selectedTab, setSelectedTab] = useState('caracteristicas');
  
    // Handle tab switch
    const handleTabClick = (tab) => {
      setSelectedTab(tab);
    };

  // Handle accessory click
  /*const handleAccessoryClick = (accesorio) => {
    setSelectedAccesorio(accesorio);
    setSelectedTab('accesorios'); // Switch to accessories tab when an accessory is clicked
  };*/

  return (
    <div className="Producto">
      <div className="row my-5 pt-5 px-2 justify-content-center">
        <div id="product">
          <div className='principal-box-product'>
            {/* Información general */}
            <div className='informacion'>
              <h1>BCC0HZF</h1>
              <h1>Double-ended cordsets</h1>
              <h3>Código de tipo</h3>
              <h3>BCC E818-E818-90-377-PS58N9-050</h3>
              <div className="image-container">
                <img src={selectedImage} alt="Selected" className="main-image" />
                <div className="thumbnail-container">
                  <button onClick={() => setSelectedImage(BCC0HZF_1)}>
                    <img src={BCC0HZF_1} alt="Thumbnail 1" className="thumbnail" />
                  </button>
                </div>
              </div>
            </div>
  
            {/* Recommended Accessories Section */}
            <div className='recommended-accessories'>
              <h2>Accesorios Recomendados</h2>
              <div className='accesorio-box'>
                <p>Se mostraran proximamente...</p>
              </div>
            </div>
  
            {/* Pestañas de navegación */}
            <div className="tabs">
              <button onClick={() => handleTabClick('caracteristicas')}>Otras Características</button>
              <button onClick={() => handleTabClick('accesorios')}>Accesorios Recomendados</button>
              <button onClick={() => handleTabClick('datasheet')}>Datasheet</button>
            </div>
  
            {/* Contenido dependiendo de la pestaña seleccionada */}
            <div className='content'>
              {selectedTab === 'caracteristicas' && (
                <div className='otras-caracteristicas'>
                  <h2>Otras Características</h2>
                  <table className="caracteristicas-tabla">
                    <tbody>
                      <tr>
                        <td>Coneccion (COM 1)</td>
                        <td>RJ45-Male, straight, 8-pin</td>
                      </tr>
                      <tr>
                        <td>Coneccion (COM 2)</td>
                        <td>RJ45-Male, straight, 8-pin</td>
                      </tr>
                      <tr>
                        <td>Interface</td>
                        <td>EtherNet/IP</td>
                      </tr>
                      <tr>
                        <td>Cable</td>
                        <td>PUR Shielded green, 5 m</td>
                      </tr>
                      <tr>
                        <td>Numero de conductores</td>
                        <td>8</td>
                      </tr>
                      <tr>
                        <td>Rango de temperatura, montura fija (COM 1)</td>
                        <td>-40...80 °C </td>
                      </tr>
                      <tr>
                        <td>Rango de temperatura, montura flexible (COM 2)</td>
                        <td>-30...70 °C</td>
                      </tr>
                      <tr>
                        <td>Voltaje de operacion Ub</td>
                        <td>60 VDC / 50 VAC </td>
                      </tr>
                      <tr>
                        <td>Corriente Nominal (40°C)</td>
                        <td>0.5 A</td>
                      </tr>
                      <tr>
                        <td>IP Rating</td>
                        <td>IP20/IP20</td>
                      </tr>
                      <tr>
                        <td> Aprovaci`on/Conformidad </td>
                        <td> CE, cULus, WEEE</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
  
              {selectedTab === 'accesorios' && (
                <div className='accesorios_recomendados'>
                  <h2>Accesorios Recomendados</h2>
                  <p>Se mostraran Proximamente...</p>
                </div>
              )}
  
              {selectedTab === 'datasheet' && (
                <div className='datasheet'>
                  <h2>Datasheet</h2>
                  <iframe
                    title="Datasheet"
                    src="https://example.com/datasheet.pdf"
                    width="100%"
                    height="600px"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  
}

export default Producto1;
