import '../../Styles/Beneficios.css';
import Logo4 from "../../images/Fichier-2-8.png"

function BeneficioContenedor({reasonTitle, reasonList, reasonImage, reasonUrl}){
  return (
    <div className="col col-12">
      <div className="row custom-row justify-content-center">
        <div className="col col-12 col-md-2 beneficios-razon-image ">
          <img src={reasonImage} alt="Beneficio" className="p-3 w-100"/>
          <h4 className="beneficios-razon-title text-center">{reasonTitle}</h4>
        </div>

        <div className="beneficios-razon col-12 col-md-5 px-5 pt-3 pb-1">
          {reasonList.map( item => 
            <li>{item}</li>
          )}
        </div>

        <div className="beneficios-razon-link col-12 col-md-2">
          <a className="btn beneficios-button" href={reasonUrl}>Learn More</a>
        </div>
      </div>
    </div>
  );
}

export default function Beneficios () {
  return (
    <div id="beneficios" className="page-section">
      <div className="beneficios">
        <div className="row custom-row py-5">
          <div className="col col-12">
            <div className="col-12 my-5">
              <h1 className="beneficios-titulo text-center">Beneficios</h1>
            </div>
            <div className="beneficios-razones col col-12">
              <div className="row justify-content-center">
                {/* TODO: reason containers should all have the same height */}

                {/* Reason 1 */}
                <BeneficioContenedor 
                  reasonTitle="Reason 1"
                  reasonList={[
                    "Capteurs sans fil de grade industriel",
                    "Alimentation par batteries lithum ion longue durée ou par courant secteur",
                    "Installation facile en quelques minutes avec aimants, vis ou colle",
                    "Autosurveillance – fiabilité garantie",
                    "Plus de 80 capteurs disponibles : vibration, température, ampérage, qualité d’huile, pression etc."
                  ]} 
                  reasonImage={Logo4} 
                  reasonUrl="/Beneficios1" 
                />

                <hr className="beneficios-razones-hr my-3"/>
                {/* Reason 2 */}
                <BeneficioContenedor 
                  reasonTitle="Reason 2"
                  reasonList={[
                    "Lien entre les capteurs et SIS-M, notre plateforme d’AIOT",
                    "Tableaux de bord et alarmes personnalisables",
                    "Moteur local de règles et d’analyse prédictive",
                    "Protection en cas de panne Internet",
                    "Protection contre les pertes de données"
                  ]} 
                  reasonImage={Logo4} 
                  reasonUrl="/Beneficios2" 
                />

                <hr className="beneficios-razones-hr my-3"/>
                {/* Reason 3 */}
                <BeneficioContenedor 
                  reasonTitle="Reason 3"
                  reasonList={[
                    "Tableaux de bord partagés multi-machines et multi-sites",
                    "Alarmes évoluées combinant les données de plusieurs procédés",
                    "Fonctions évoluées d’analyse statistique des données",
                    "Analyse prédictive par Intelligence Artificielle et Machine Learning",
                    "Télésurveillance des capteurs"
                  ]} 
                  reasonImage={Logo4} 
                  reasonUrl="/Beneficios3" 
                />

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
