import '../Styles/CallToAction.css';
import Navbar from './Shared/Navbar.js'
import CTA from '../Components/ContactUs/CTA.js'
import ContactUs from './Shared/contactUs.js'
import Footer from './Shared/footer.js'

function CallToAction() {
  return (
    <>
      <Navbar />

      <CTA />

      <ContactUs />
      <Footer />
    </>
  );
}

export default CallToAction;
