import React, { useState } from 'react';
import '../../Styles/Navbar.css';
import Logo from "../../images/EAE logo.svg";

function NavItem({ itemName, itemUrl, subItems }) {
  const [isOpen, setIsOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [activeContent, setActiveContent] = useState(null);

  const handleToggle = (event) => {
    event.preventDefault();
    setIsOpen(!isOpen);
    setActiveContent(null);
  };

  const handleSubmenuToggle = (subItem, event) => {
    event.preventDefault();
    setOpenSubmenu(subItem);
    setActiveContent(null);
  };

  const handleContentDisplay = (content, event) => {
    event.preventDefault();
    setActiveContent(content);
  };

  return (
    <li className={`nav-item ${subItems ? 'dropdown' : ''}`}>
      <a
        className={`nav-link text-dark ${subItems ? 'btn btn-link' : ''}`}
        href={itemUrl}
        onClick={subItems ? handleToggle : null}
      >
        {itemName}
      </a>
      {subItems && isOpen && (
        <div className="dropdown-menu-container">
          <ul className="dropdown-menu show">
            {subItems.map((subItem, index) => (
              <li key={index} className="dropdown-submenu">
                <a
                  className="dropdown-item"
                  href={subItem.url}
                  onClick={(event) => handleSubmenuToggle(subItem, event)}
                >
                  {subItem.name}
                </a>
              </li>
            ))}
          </ul>
          {openSubmenu && (
            <div className="sub-menu-content">
              <ul>
                {openSubmenu.subItems.map((subSubItem, subIndex) => (
                  <li key={subIndex}>
                    <a
                      className="dropdown-item"
                      href={subSubItem.url}
                      onClick={(event) => handleContentDisplay(subSubItem.subItems, event)}
                    >
                      {subSubItem.name}
                    </a>
                  </li>
                ))}
              </ul>
              {activeContent && (
                <ul className="content-list">
                  {activeContent.map((item, contentIndex) => (
                    <li key={contentIndex}>
                      <a className="dropdown-item" href={item.url}>
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
      )}
    </li>
  );
}

export default function Navbar() {
  const productosSubItems = [
    {
      name: "Sensores",
      url: "#",
      subItems: [
        { 
          name: "Sensores de Monitoreo de Condicion", 
          url: "#", 
          subItems: [
            { name: "BCM0001", url: "/BCM0001" },
            { name: "BCM0002", url: "/BCM0002" },
            { name: "BCM0003", url: "/BCM0003" },
            { name: "BCM0004", url: "/BCM0004" }
          ]
        },
        { name: "", url: "#", subItems: [] },
      ]
    },
    {
      name: "Soluciones de Sistema",
      url: "#",
      subItems: [
        { name: "Monitoreo", url: "#", subItems: [{
          name: "Condition Monitoring Toolkit", url: "/BAV002N"}
        ] },
      ]
    },
    {
      name: "Redes Industriales",
      url: "#",
      subItems: [
        {
          name: "Bloque de redes", url: "#", subItems:[
            {name: "Bloque de Redes Ethernet - BN100HM", url: "/BN100HM"},
            {name: "Bloque de Redes Ethernet - BNI006A", url: "/BNI006A"},
            {name: "Bloque de Redes Ethernet - BNI009T", url: "/BNI009T"},
            {name: "Bloque de Redes Ethernet - BNI00H7", url: "/BNI00H7"},

          ]
        }
      ]
    },
    {
      name: "Conectividad",
      url: '#',
      subItems: [
        {
          name: "Cables de doble extremo", url: "#", subItems:[
            {name: "Cables de doble extremo - BCC03RK", url: "/BCC03RK"},
            {name: "Cables de doble extremo - BCC03RM", url: "/BCC03RM"},
            {name: "Cables de doble extremo - BCC0HZF", url: "/BCC0HZF"},
            {name: "Cables de doble extremo - BCC0HZE", url: "/BCC0HZE"}
          ]
        }
      ]
    },
    {
      name: "Fuentes de Alimentacion",
      url: '#',
      subItems: [
        {
          name: "UPS Cabinete de control", url: "#", subItems:[
            {name: "UPS - BAE0111", url: "/BAE0111"}
          ]
        }
      ]
    }
  ];

  return (
    <nav className="navbar navbar-expand-sm navbar-light">
      <div className="container-fluid">
        <button
          className="navbar-toggler ms-2 mt-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target=".navbar-collapse"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="navbar-collapse collapse d-sm-inline-flex justify-content-between bg-white">
          <ul className="navbar-nav flex-grow-1 p-2">
            <li className="d-none d-md-block">
              <a href="#hero_section">
                <img src={Logo} alt="Nav Logo" className="navbar-logo" />
              </a>
            </li>
            <NavItem
              itemName="Inicio"
              itemUrl="/#hero_section"
            />
            <NavItem
              itemName="Productos"
              subItems={productosSubItems}
            />
            <NavItem
              itemName="Technologias"
              itemUrl="/#tecnologias"
            />
            <NavItem
              itemName="Sobre Nosotros"
              itemUrl="/#sobre-nosotros"
            />
            <NavItem
              itemName="Beneficios"
              itemUrl="/#beneficios"
            />
            <NavItem
              itemName="Contact Us"
              itemUrl="/ContactUs"
            />
          </ul>
        </div>
      </div>
    </nav>
  );
}
