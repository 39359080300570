import '../../../Styles/hero_section.css';
import Logo from "../../../images/EAE logo.svg"

export default function HeroSection () {
  return (
    <div id="hero_section" className="page-section">
      <div className="hero-section">
        <div className="row custom-row">
          {/* Logo */}
          <div className="col col-12 hero-logo text-end">
            {/* TODO: which side to put the logo? */}
            <img src={Logo} alt="EAE Insights Logo" className="mt-3 mt-md-0 me-3 me-md-0"/>
          </div>

          {/* Hero description */}
          <div className="col col-12 col-sm-6 hero-discription text-end mb-2">
            <h1 className="me-5">EAE Insights Data & IoT</h1>
            <p className="me-5">INTELIGENCIA ARTIFICIAL AL SERVICIO DE LA INDUSTRIA</p>
          </div>

          {/* Contact Button */}
          <div className="col col-12 col-sm-6 hero-cta text-sm-start text-center mb-3 mt-3 mt-md-0">
            <a className="btn hero-button" href="/ContactUs">Contact us</a>
          </div>

        </div>
      </div>
    </div>
  );
}
