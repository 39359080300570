import React, { useState } from 'react';
import '../../../Styles/Productos.css';
import BCM0003 from "../../../images/BCM0003-1.png";
import BCM00032 from "../../../images/BCM0003-2.png";
import BCM00033 from "../../../images/BCM0003-3.png";
import BCM00034 from "../../../images/BCM0003-4.png";
import BCM00035 from "../../../images/BCM0003-5.png";
import BCM00036 from "../../../images/BCM0003-6.png";


function Producto1() {
  const [selectedImage, setSelectedImage] = useState(BCM0003);

  return (
    <div className="Producto">
      <div className="row my-5 pt-5 px-2 justify-content-center">
        <div id="product">
          <div className='principal-box-product'>
            <div className='informacion'>
              <h1>BCM0003</h1>
              <h3>Código de tipo</h3>
              <h3>BCM R16E-004-CI02-01,5-S4</h3>
              <div className="image-container">
                <img src={selectedImage} alt="Selected" className="main-image" />
                <div className="thumbnail-container">
                  <button onClick={() => setSelectedImage(BCM0003)}>
                    <img src={BCM0003} alt="Thumbnail 1" className="thumbnail" />
                  </button>
                  <button onClick={() => setSelectedImage(BCM00032)}>
                    <img src={BCM00032} alt="Thumbnail 2" className="thumbnail" />
                  </button>
                  <button onClick={() => setSelectedImage(BCM00033)}>
                    <img src={BCM00033} alt="Thumbnail 3" className="thumbnail" />
                  </button>
                  <button onClick={() => setSelectedImage(BCM00034)}>
                    <img src={BCM00034} alt="Thumbnail 3" className="thumbnail" />
                  </button>
                  <button onClick={() => setSelectedImage(BCM00035)}>
                    <img src={BCM00035} alt="Thumbnail 3" className="thumbnail" />
                  </button>
                  <button onClick={() => setSelectedImage(BCM00036)}>
                    <img src={BCM00036} alt="Thumbnail 3" className="thumbnail" />
                  </button>
                </div>
              </div>
            </div>
            <div className='caracteristicas'>
              <div className='funciones'>
                <h2>Función</h2>
                <ul>
                  <li><span className="check-icon">✔</span> Análisis de vibraciones en el dominio del tiempo</li>
                  <li><span className="check-icon">✔</span> Analisis de vibraciones en el rango de frecuencias</li>
                  <li><span className="check-icon">✔</span> Entrada de velocidad de rotacion</li>
                  <li><span className="check-icon">✔</span> Monitoreo de temperatura de contacto</li>
                </ul>
              </div>
              <div className='vibracion-rango'>
                <h2>Vibración</h2>
                <p>    2... 4500 Hz (+-10 %)</p>
                <br />
                <h2>Rango de Frecuencia</h2>
                <p>    2... 6000 Hz (+-3 dB)</p>
              </div>
            </div>
            <div className='otras-caracteristicas'>
              <h2>Otras Características</h2>
              <table className="caracteristicas-tabla">
                <tbody>
                  <tr>
                    <td>Vibración, número de ejes de medición</td>
                    <td>3</td>
                  </tr>
                  <tr>
                    <td>Vibración, rango de medición</td>
                    <td>-16... 16g</td>
                  </tr>
                  <tr>
                    <td>Temperatura de contacto, rango de medición</td>
                    <td>-40... +80 ºC</td>
                  </tr>
                  <tr>
                    <td>Caracteristicas secundarias para la monitorizacion del estado</td>
                    <td>Monitoreo de temperatura interna</td>
                  </tr>
                  <tr>
                    <td>Tensión nominal de funcionamiento Ue DC</td>
                    <td>24 V</td>
                  </tr>
                  <tr>
                    <td>Interfaz</td>
                    <td>IO-Link 1.1</td>
                  </tr>
                  <tr>
                    <td>Conexión</td>
                    <td>Cable con conector, M12x1-Macho, 4 polos, 1.5 m, PUR</td>
                  </tr>
                  <tr>
                    <td>Material de la carcasa</td>
                    <td>Acero inoxidable 1.4404 (316L)</td>
                  </tr>
                  <tr>
                    <td>Clasificación IP</td>
                    <td>IP67, IP68, IP69K</td>
                  </tr>
                  <tr>
                    <td>Temperatura ambiente</td>
                    <td>-40... 80ºC</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Producto1;
