import React, { useState } from 'react';
import '../../../Styles/Productos.css';
import BCM0001 from "../../../images/BCM0002-1.png";



function Producto1() {
  const [selectedImage, setSelectedImage] = useState(BCM0001);

  return (
    <div className="Producto">
      <div className="row my-5 pt-5 px-2 justify-content-center">
        <div id="product">
          <div className='principal-box-product'>
            <div className='informacion'>
              <h1>BCM0001</h1>
              <h3>Código de tipo</h3>
              <h3>BCM R15E-001-D100-01,5-S4</h3>
              <div className="image-container">
                <img src={selectedImage} alt="Selected" className="main-image" />
                <div className="thumbnail-container">
                  <button onClick={() => setSelectedImage(BCM0001)}>
                    <img src={BCM0001} alt="Thumbnail 1" className="thumbnail" />
                  </button>
                </div>
              </div>
            </div>
            <div className='caracteristicas'>
              <div className='funciones'>
                <h2>Función</h2>
                <ul>
                  <li><span className="check-icon">✔</span> Análisis de vibraciones en el dominio del tiempo</li>
                  <li><span className="check-icon">✔</span> Control de la temperatura de contacto</li>
                </ul>
              </div>
              <div className='vibracion-rango'>
                <h2>Vibración</h2>
                <p>    2... 1800 Hz (+-10 %)</p>
                <br />
                <h2>Rango de Frecuencia</h2>
                <p>    2... 2500 Hz (+-3 dB)</p>
              </div>
            </div>
            <div className='otras-caracteristicas'>
              <h2>Otras Características</h2>
              <table className="caracteristicas-tabla">
                <tbody>
                  <tr>
                    <td>Vibración, número de ejes de medición</td>
                    <td>3</td>
                  </tr>
                  <tr>
                    <td>Vibración, rango de medición</td>
                    <td>-16... 16g</td>
                  </tr>
                  <tr>
                    <td>Temperatura de contacto, rango de medición</td>
                    <td>-25... +70 ºC</td>
                  </tr>
                  <tr>
                    <td>Tensión nominal de funcionamiento Ue DC</td>
                    <td>24 V</td>
                  </tr>
                  <tr>
                    <td>Interfaz</td>
                    <td>IO-Link 1.1</td>
                  </tr>
                  <tr>
                    <td>Conexión</td>
                    <td>Cable con conector, M12x1-Macho, 3 pines, 1.5 m, PUR</td>
                  </tr>
                  <tr>
                    <td>Material de la carcasa</td>
                    <td>Acero inoxidable 1.4404</td>
                  </tr>
                  <tr>
                    <td>Clasificación IP</td>
                    <td>IP67, IP68, IP69K</td>
                  </tr>
                  <tr>
                    <td>Temperatura ambiente</td>
                    <td>-25... 70ºC</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Producto1;
