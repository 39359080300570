import '../../Styles/CallToAction.css';
import Navbar from '.././Shared/Navbar.js'
import ContactUs from '.././Shared/contactUs.js'
import Footer from '.././Shared/footer.js'
import Sensor2 from '../../Components/Productos/Sensores/Sensor2.js';


function BenefitsPages1() {
  return (
    <>
      <Navbar />

      <Sensor2 />

      <ContactUs />
      <Footer />
    </>
  );
}

export default  BenefitsPages1;
