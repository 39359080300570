import '../Styles/CallToAction.css';
import Navbar from './Shared/Navbar.js'
import ContactUs from './Shared/contactUs.js'
import Footer from './Shared/footer.js'
import Beneficios3 from '../Components/Benefits/Beneficios3.js';


function BenefitsPages3() {
  return (
    <>
      <Navbar />

      <Beneficios3 />

      <ContactUs />
      <Footer />
    </>
  );
}

export default  BenefitsPages3;
