import '../../../Styles/hero_section_columns.css';
import ImgDataIngestion from "../../../images/Fichier-2-8.png"
import ImgDataProcessing from "../../../images/Fichier-2-8.png"
import ImgDataTransmittingAndStorage from "../../../images/Fichier-2-8.png"
import ImgDataInsights from "../../../images/Fichier-2-8.png"

function HeroColumn({title, list, image}){
  return(
    <div className="col col-12 col-md-3 hero-column px-4">
      {/* TODO: figure out how to paly animation when object is in view */}
      <div className="hero-column-image">
        <img src={image} alt="Col display" className="p-3"/>
      </div>
      <h1 className="text-center">{title}</h1>
      {list.map(item => 
        <li>{item}</li>
      )}
    </div>
  );
}

export default function HeroSectionColumns () {
  return (
    /* TODO: Displays only for large screens */
    // <div id="hero_section_columns" className="page-section d-none d-lg-block">
    <div id="hero_section_columns" className="page-section">
      <div className="hero-section-columns row custom-row">
        <div className="col col-10 offset-1">
          <div className="row my-5 py-5">
            <HeroColumn 
              image={ImgDataIngestion}
              title="Data Ingestion" 
              list={[
                "Sensors connected to the industrial machinery generate streams of data that provide a snapshots of the state of the machines as they perfomed their tasks."
              ]} 
            />
            <HeroColumn 
              image={ImgDataProcessing}
              title="Data Processing" 
              list={[
                "The data stream are then captured by IoT Edge devices on primises which filter and analyze them clean and compact the volume of information that needs to be transmitted to long term storage."
              ]} 
            />
            <HeroColumn 
              image={ImgDataTransmittingAndStorage}
              title="Data Transmitting and Storage" 
              list={[
                "The combination of all IoT Edge Devices generate a constant flow of clean data that is stored in the cloud in data lakes for on demand visualization and further analysis."
              ]} 
            />
            <HeroColumn 
              image={ImgDataInsights}
              title="Data Insights" 
              list={[
                "In the cloud the incoming data and the data already collected are constantly analyze throught various process and filters to get insights on trends that help better understand the current and future performance of industrial equipment."
              ]} 
            />
          </div>
        </div>
      </div>
    </div>
  );
}
