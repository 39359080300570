import '../../Styles/SectionDivider.css';

export default function SectionDivider ({color1, color2}) {
    // NOTE: Smooth section transitions 
    const style = {
    background: 'linear-gradient(180deg, ' + 
      'rgba(' + 
      color1[0] + ',' + 
      color1[1] + ',' + 
      color1[2] + ', 1) 0%, ' + 

      'rgba(' + 
      color1[0] + ',' + 
      color1[1] + ',' + 
      color1[2] + ', 1) 40%, ' + 

      'rgba(' + 
      color2[0] + ',' + 
      color2[1] + ',' + 
      color2[2] + ', 1) 60%, ' + 

      'rgba(' +
      color2[0] + ',' + 
      color2[1] + ',' + 
      color2[2] + ', 1) 100%)'
  };
  return (
    <>
      <div className="section-divider" 
        style={style}>
      </div>
    </>
  );
}
