import '../Styles/CallToAction.css';
import Navbar from './Shared/Navbar.js'
import ContactUs from './Shared/contactUs.js'
import Footer from './Shared/footer.js'
import Producto1 from '../Components/Productos/ProductoEspecifico.js';


function BenefitsPages1() {
  return (
    <>
      <Navbar />

      <Producto1 />

      <ContactUs />
      <Footer />
    </>
  );
}

export default  BenefitsPages1;
