import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './Pages/HomePage.js';
import CallToAction from './Pages/CallToAction.js';
import BenefitsPages1 from './Pages/BenefitsPage1.js';
import BenefitsPages3 from './Pages/BenefitsPage3.js';
import BenefitsPages2 from './Pages/BenefitsPage2.js';
import ProdutoPage1 from './Pages/ProductoPage1.js';
import ProdutoPage2 from './Pages/Productos/ProductPage2.js';
import ProdutoPage3 from './Pages/Productos/ProductPage3.js';
import ProdutoPage4 from './Pages/Productos/ProductPage4.js';
import ProdutoPageCMTK from './Pages/Productos/ProductoPage5CMTK.js';
import ProdutoPageBN100HM from './Pages/Productos/ProductoBN100HM.js';
import ProductoBNI006A from './Pages/Productos/ProductoBNI006A.js';
import ProductoBNI009T from './Pages/Productos/ProductoBNI009T.js'
import ProductoBNI00H7 from './Pages/Productos/ProductoBNI00H7.js'
import ProdutoPageBCC03RK from './Pages/Productos/ProductoBCC03RK.js';
import ProdutoPageBCC03RM from './Pages/Productos/ProductoBCC03RM.js';
import ProdutoPageBCC0HZF from './Pages/Productos/ProductoBCC0HZF.js';
import ProdutoPageBCC0HZE from './Pages/Productos/ProductoBCC0HZE.js';
import ProductoBEAE0111 from './Pages/Productos/productoBAE0111.js';
import './Styles/App.css';
import './Styles/sections.css';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.js"

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/ContactUs" element={<CallToAction />} />
        <Route path="/Beneficios1" element={<BenefitsPages1 />} />
        <Route path="/Beneficios2" element={<BenefitsPages2 />} />
        <Route path="/Beneficios3" element={<BenefitsPages3 />} />
        <Route path="/BCM0001" element={<ProdutoPage2 />} />
        <Route path="/BCM0002" element={<ProdutoPage1 />} />
        <Route path="/BCM0003" element={<ProdutoPage3 />} />
        <Route path="/BCM0004" element={<ProdutoPage4 />} />
        <Route path="/BAV002N" element={<ProdutoPageCMTK />} />
        <Route path="/BN100HM" element={<ProdutoPageBN100HM />} />
        <Route path="/BNI006A" element={<ProductoBNI006A />} />
        <Route path="/BNI009T" element={<ProductoBNI009T />} />
        <Route path="/BNI00H7" element={<ProductoBNI00H7 />} />
        <Route path="/BCC03RK" element={<ProdutoPageBCC03RK />} />
        <Route path="/BCC03RM" element={<ProdutoPageBCC03RM />} />
        <Route path="/BCC0HZF" element={<ProdutoPageBCC0HZF />} />
        <Route path="/BCC0HZE" element={<ProdutoPageBCC0HZE />} />
        <Route path="/BAE0111" element={<ProductoBEAE0111 />} />
      </Routes>
    </Router>
  );
}

export default App;
