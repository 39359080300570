import '../../Styles/CallToAction.css';
import Navbar from '../Shared/Navbar.js'
import ContactUs from '../Shared/contactUs.js'
import Footer from '../Shared/footer.js'
import BNI009T from '../../Components/Productos/Redes/BNI009T.js';


function BenefitsPages1() {
  return (
    <>
      <Navbar />

      <BNI009T />

      <ContactUs />
      <Footer />
    </>
  );
}

export default  BenefitsPages1;
