import '../../Styles/CallToAction.css';
import Navbar from '.././Shared/Navbar.js'
import ContactUs from '.././Shared/contactUs.js'
import Footer from '.././Shared/footer.js'
import CMTK from '../../Components/Productos/System_solution/CMTK.js';


function BenefitsPages1() {
  return (
    <>
      <Navbar />

      <CMTK />

      <ContactUs />
      <Footer />
    </>
  );
}

export default  BenefitsPages1;
