import '../../../Styles/Tecnologias.css';
import Logo4 from "../../../images/Fichier-2-8.png"

function TecnologiaContenedor({techImage, techTitle, techDetails}){
  return (
    <div className="col col-12 col-md-2 px-2">
      <div className="row justify-content-center">
        <div className="tech-image col col-12">
          <img src={techImage} alt="Tech" className="p-3 w-100"/>
        </div>

        <div className="tech-title col col-12 px-2">
          <h6>{techTitle}</h6>
        </div>

        <div className="tech-details col-12 px-4">
          {techDetails.map( item => 
            <li>{item}</li>
          )}
        </div>

      </div>
    </div>
  );
}

export default function Tecnologias () {
  return (
    <div id="tecnologias" className="page-section">
      <div className="tecnologias">
        <div className="row custom-row">
          <div className="col col-10 col-md-12 offset-md-0 offset-1">
            <div className="col-12 my-5">
              <h1 className="tecnologias-titulo my-5 pt-5 text-center">Tecnologias</h1>
            </div>
            <div className="tech-list col col-12 mb-5 pb-5">
              <div className="row justify-content-center">

                {/* Tech 1 */}
                <TecnologiaContenedor 
                  techImage={Logo4} 
                  techTitle="A.I. Prediction"
                  techDetails={[
                    "Testing Automation",
                    "Testing Deployment",
                    "Testing CI/CD"
                  ]}
                />

                {/* Tech 2 */}
                <TecnologiaContenedor 
                  techImage={Logo4} 
                  techTitle="A.I. Prediction"
                  techDetails={[
                    "Tacos",
                    "Hamburgers",
                    "Baleadas"
                  ]}
                />

                {/* Tech 3 */}
                <TecnologiaContenedor 
                  techImage={Logo4} 
                  techTitle="A.I. Prediction"
                  techDetails={[
                    "Tacos",
                    "Hamburgers",
                    "Baleadas"
                  ]}
                />

                {/* Tech 4 */}
                <TecnologiaContenedor 
                  techImage={Logo4} 
                  techTitle="A.I. Prediction"
                  techDetails={[
                    "Tacos",
                    "Hamburgers",
                    "Baleadas"
                  ]}
                />

                {/* Tech 5 */}
                <TecnologiaContenedor 
                  techImage={Logo4} 
                  techTitle="A.I. Prediction"
                  techDetails={[
                    "Tacos",
                    "Hamburgers",
                    "Baleadas"
                  ]}
                />


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
