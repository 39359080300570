import React, { useState } from 'react';
import '../../../Styles/Sobre_nosotros.css';
import tempImage1 from "../../../images/tempImage1.png";
import tempImage2 from "../../../images/tempImage2.png";

function DisplayDetail({activeSection}) {
  return(
    <>
      <div className={`col col-12 px-3 ${activeSection === 'iot' ? 'active' : 'inactive'}`}>
        {activeSection === 'iot' && (
          <>
            <h2>Integración de Soluciones IoT</h2>
            <p>
              Implementación de sensores y dispositivos inteligentes en maquinaria y equipos para recopilar datos en tiempo real sobre su estado y rendimiento. Esto permite un monitoreo continuo y detallado de las condiciones operativas, facilitando la detección temprana de posibles fallos o necesidades de mantenimiento.
            </p>
          </>
        )}
      </div>
      <div className={`col col-12 px-3 ${activeSection === 'mantenimiento' ? 'active' : 'inactive'}`}>
        {activeSection === 'mantenimiento' && (
          <>
            <h2>Mantenimiento Predictivo</h2>
            <p>
              Utilización de algoritmos avanzados y aprendizaje automático para analizar los datos recopilados y predecir posibles fallos antes de que ocurran. Esto permite planificar el mantenimiento de manera proactiva, evitando paradas no programadas y extendiendo la vida útil de los equipos.
            </p>
          </>
        )}
      </div>


      {/* Repetir para las demás secciones */}
      {/* Sección de análisis de datos y visualización */}
      <div className={`col col-12 px-3 ${activeSection === 'analisis' ? 'active' : 'inactive'}`}>
        {activeSection === 'analisis' && (
          <>
            <h2>Análisis de Datos y Visualización</h2>
            <p>
              Ofrecimiento de plataformas y herramientas para el análisis y la visualización de datos, permitiendo a los usuarios comprender fácilmente el estado de sus activos y tomar decisiones basadas en información precisa y actualizada.
            </p>
          </>
        )}
      </div>

      {/* Sección de optimización de procesos */}
      <div className={`col col-12 px-3 ${activeSection === 'optimizacion' ? 'active' : 'inactive'}`}>
        {activeSection === 'optimizacion' && (
          <>
            <h2>Optimización de Procesos</h2>
            <p>
              Asesoramiento en la implementación de mejoras en los procesos de producción y mantenimiento, basadas en los insights obtenidos a través del monitoreo y análisis de datos, para aumentar la eficiencia y reducir costos.
            </p>
          </>
        )}
      </div>


      {/* Sección de capacitación y soporte */}
      <div className={`col col-12 px-3 ${activeSection === 'capacitacion' ? 'active' : 'inactive'}`}>
        {activeSection === 'capacitacion' && (
          <>
            <h2>Capacitación y Soporte</h2>
            <p>
              Proporcionar capacitación y soporte técnico para asegurar que los clientes puedan maximizar el valor de las soluciones IoT implementadas, incluyendo asistencia en la configuración, operación y mantenimiento de los sistemas.
            </p>
          </>
        )}
      </div>

      {/* Sección de seguridad de datos */}
      <div className={`col col-12 px-3 ${activeSection === 'seguridad' ? 'active' : 'inactive'}`}>
        {activeSection === 'seguridad' && (
          <>
            <h2>Seguridad de Datos</h2>
            <p>
              Implementación de protocolos de seguridad robustos para garantizar la protección de los datos industriales recopilados, asegurando su integridad y confidencialidad.
            </p>
          </>
        )}
      </div>

      {/* Sección de integración de sistemas */}
      <div className={`col col-12 px-3 ${activeSection === 'integracion-sistemas' ? 'active' : 'inactive'}`}>
        {activeSection === 'integracion-sistemas' && (
          <>
            <h2>Integración de Sistemas</h2>
            <p>
              Capacidad para integrar las soluciones IoT con otros sistemas existentes en la organización, como ERP o sistemas de gestión de mantenimiento, para una operación más cohesiva y eficiente.
            </p>
          </>
        )}
      </div>

      {/* Sección de personalización y escalabilidad */}
      <div className={`col col-12 px-3 ${activeSection === 'personalizacion' ? 'active' : 'inactive'}`}>
        {activeSection === 'personalizacion' && (
          <>
            <h2>Personalización y Escalabilidad</h2>
            <p>
              Desarrollo de soluciones personalizadas que se adaptan a las necesidades específicas de cada cliente y que pueden escalarse a medida que la empresa crece o cambian sus necesidades.
            </p>
          </>
        )}
      </div>
    </>
  );
}

export default function SobreNosotros() {
  const [activeSection, setActiveSection] = useState(null);

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };
  // col-10 col offset-1
  return (
    <div id="sobre-nosotros" className="page-section">
      <div className="sobre-nosotros row custom-row text-center justify-content-center pb-5 ">
        <div className="col col-10">
          <div className="row custom-row ">
            <div className="col col-12">
              <h1 className="my-5 pt-5 sobre-nosotros-title">Sobre Nosotros</h1>
            </div>
            <div className="sobre-nosotros-details">
              <DisplayDetail activeSection={activeSection} />
            </div>
          </div>

          <div className="row custom-row justify-content-center">
            {/* Image 1 */}
            <div className="col col-12 col-md-3 d-none d-md-block">
              <img src={tempImage1} alt="Display 1"/>
            </div>

            {/* List of content */}
            <div className="col col-12 col-md-6">
              <ul className="section-menu">
                <li className={activeSection === 'iot' ? 'active' : 'inactive'} 
                  onClick={() => toggleSection('iot')}>Integración de Soluciones IoT</li>
                <li className={activeSection === 'mantenimiento' ? 'active' : 'inactive'} 
                  onClick={() => toggleSection('mantenimiento')}>Mantenimiento Predictivo</li>
                <li className={activeSection === 'analisis' ? 'active' : 'inactive'} 
                  onClick={() => toggleSection('analisis')}>Análisis de Datos y Visualización</li>
                <li className={activeSection === 'optimizacion' ? 'active' : 'inactive'} 
                  onClick={() => toggleSection('optimizacion')}>Optimización de Procesos</li>
                <li className={activeSection === 'capacitacion' ? 'active' : 'inactive'} 
                  onClick={() => toggleSection('capacitacion')}>Capacitación y Soporte</li>
                <li className={activeSection === 'seguridad' ? 'active' : 'inactive'} 
                  onClick={() => toggleSection('seguridad')}>Seguridad de Datos</li>
                <li className={activeSection === 'integracion-sistemas' ? 'active' : 'inactive'} 
                  onClick={() => toggleSection('integracion-sistemas')}>Integración de Sistemas</li>
                <li className={activeSection === 'personalizacion' ? 'active' : 'inactive'} 
                  onClick={() => toggleSection('personalizacion')}>Personalización y Escalabilidad</li>
              </ul>
            </div>

            {/* Image 2 */}
            <div className="col col-12 col-md-3 d-none d-md-block">
              <img src={tempImage2} alt="Display 2"/>
            </div>         
          </div>
        </div>

      </div>
    </div>
  );
}
