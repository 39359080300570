import React, { useState } from 'react';
import '../../Styles/CTA.css';

export default function CTA() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    companyAddress: '',
    comment: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      console.log(JSON.stringify(formData));
      const response = await fetch('http://localhost:5013/Quote', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setSubmitSuccess(true);
      if (submitSuccess === true){
        successForm();
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  function successForm () {
    document.getElementById('form-post-success').classList.add('fade-toggle');

    setTimeout(function() {
      document.getElementById('form-post-success').classList.remove('fade-toggle');
    }, 3000);
  }

  return (
    <div id="cta" className="page-section">
      <div className="CTA">
        <div className="row custom-row justify-content-center mb-3">
          <div className="col text-center col-12 mt-5 pt-3 cta-title">
            <h1>Contact Us</h1>
          </div>
          <div className="col col-12 col-md-8">

            <form className="p-5 pt-3" onSubmit={handleSubmit}>

              <div className="row">
                <p className="col-12 text-start">Write us an email and we'll get in contact with you in 2 business days</p>
                <div className="form-group mt-2 col-12 col-md-6">
                  <label>First Names</label>
                  {/* TODO: Add client side field validation */} 
                  <input type="text" 
                    className="form-control" 
                    name="firstName" 
                    placeholder="Enter names"
                    value={formData.firstName}
                    onChange={handleChange}
                  ></input>
                </div>

                <div className="form-group mt-2 col-12 col-md-6">
                  <label>Last Names</label>
                  <input type="text" 
                    className="form-control" 
                    name="lastName" 
                    placeholder="Enter names"
                    value={formData.lastName}
                    onChange={handleChange}
                  ></input>
                </div>
              </div>

              <div className="row">
                <div className="form-group mt-2 col-12 col-md-6">
                  <label>Email</label>
                  <input type="email" 
                    className="form-control" 
                    name="email" 
                    placeholder="Enter email"
                    value={formData.email}
                    onChange={handleChange}
                  ></input>
                </div>

                <div className="form-group mt-2 col-12 col-md-6">
                  <label>Phone Number</label>
                  <input type="tel" 
                    className="form-control" 
                    name="phoneNumber" 
                    placeholder="Enter phone number"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                  ></input>
                </div>
              </div>

              <div className="form-group mt-2">
                <label>Company name</label>
                <input type="text" 
                  className="form-control" 
                  name="companyName" 
                  placeholder="Enter company name"
                  value={formData.companyName}
                  onChange={handleChange}
                ></input>
              </div>

              <div className="form-group mt-2">
                <label>Company address</label>
                <input type="text" 
                  className="form-control" 
                  name="companyAddress" 
                  placeholder="Enter address"
                  value={formData.companyAddress}
                  onChange={handleChange}
                ></input>
              </div>

              <div className="form-group mt-2">
                <label>Message</label>
                <textarea type="text" 
                  className="form-control" 
                  name="comment" 
                  placeholder="Write a message"
                  value={formData.comment}
                  onChange={handleChange}
                ></textarea>
              </div>

              <div className="text-center">
                <button type="submit" 
                  disabled={isSubmitting}
                  className="btn cta-btn my-2"
                >
                  {isSubmitting ? 'Submitting...' : 'Submit'}
                </button>
              </div>

              <div id="form-post-success" className="text-center bg-success text-white text-center p-3 form-post-success">
                <span>
                  Form submitted successfully!
                </span>
              </div>

              {/* TODO: Make this a pop up message */} 
              {error && <div>Error: {error.message}</div>}

            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
