import React, { useState } from 'react';
import '../../../Styles/Productos.css';
import BCM0001 from "../../../images/BAV002N.png";
import Ethernet from "../../../images/CMTK/Ethernet.png";
import Sensors from "../../../images/CMTK/Sensors.png";
import Cordsets from "../../../images/CMTK/Cordsets.png";
import Supplies from "../../../images/CMTK/Supplies.png";
import BNI00HM from "../../../images/CMTK/Ethernet/BNI00HM.png";
import BNI006A from "../../../images/CMTK/Ethernet/BNI006A.png";
import BNI009T from "../../../images/CMTK/Ethernet/BNI009T.png";
import BNI00H7 from "../../../images/CMTK/Ethernet/BNI00H7.png";
import BCC0HZE from "../../../images/CMTK/Cordsets/BCC0HZE.png";
import BCC03RK from "../../../images/CMTK/Cordsets/BCC03RK.png";
import BCC03RM from "../../../images/CMTK/Cordsets/BCC03RM.png";
import BCC0HZF from "../../../images/CMTK/Cordsets/BCC0HZF.png";
import BCM0004 from "../../../images/CMTK/Sensors/BCM0004.png";
import BCM0003 from "../../../images/CMTK/Sensors/BCM0003.png";
import BCM0002 from "../../../images/CMTK/Sensors/BCM0002.png";
import BCM00011 from "../../../images/CMTK/Sensors/BCM0001.png";
import BAE0111 from "../../../images/CMTK/Supplies/BAE0111.png"
function Producto1() {
  const [selectedImage, setSelectedImage] = useState(BCM0001);
  const [selectedTab, setSelectedTab] = useState('caracteristicas');
  const [selectedAccesorio, setSelectedAccesorio] = useState(null);

  const accesorios = [
    {
      id: 1, name: 'Ethernet / IP', description: 'Conexión de alta velocidad.',
      image: Ethernet, modelCode: 'ETH001', link: '/productos/eth001'
    },
    {
      id: 2, name: 'Double-Ended Cordset', description: 'Cable de doble extremo para múltiples usos.',
      image: Cordsets, modelCode: 'CORD002', link: '/productos/cord002'
    },
    {
      id: 3, name: 'Sensores de monitoreo', description: 'Sensores multifuncionales para monitoreo.',
      image: Sensors, modelCode: 'SENSOR003', link: '/productos/sensor003'
    },
    {
      id: 4, name: 'UPS para gabinete', description: 'UPS para garantizar el suministro de energía.',
      image: Supplies, modelCode: 'SUPPLY004', link: '/productos/supply004'
    }
  ];



  // Handle tab switch
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    if (tab !== 'accesorios') {
      setSelectedAccesorio(null); // Reset accessory when switching away from accessories tab
    }
  };

  // Handle accessory click
  const handleAccessoryClick = (accesorio) => {
    setSelectedAccesorio(accesorio);
    setSelectedTab('accesorios'); // Switch to accessories tab when an accessory is clicked
  };

  return (
    <div className="Producto">
      <div className="row my-5 pt-5 px-2 justify-content-center">
        <div id="product">
          <div className='principal-box-product'>
            {/* Información general */}
            <div className='informacion'>
              <h1>BAV002N</h1>
              <h1>Condition Monitoring Toolkit</h1>
              <h3>Código de tipo</h3>
              <h3>BAV MA-NC-00025-01</h3>
              <div className="image-container">
                <img src={selectedImage} alt="Selected" className="main-image" />
                <div className="thumbnail-container">
                  <button onClick={() => setSelectedImage(BCM0001)}>
                    <img src={BCM0001} alt="Thumbnail 1" className="thumbnail" />
                  </button>
                </div>
              </div>
            </div>
  
            {/* Recommended Accessories Section */}
            <div className='recommended-accessories'>
              <h2>Accesorios Recomendados</h2>
              <div className='accesorio-box'>
                {accesorios.map(accesorio => (
                  <div
                    key={accesorio.id}
                    className='accesorios-boxs'
                    onClick={() => handleAccessoryClick(accesorio)}
                  >
                    <img src={accesorio.image} alt={accesorio.name} className='accesorio-image' />
                    <hr />
                    <h3>{accesorio.name}</h3>
                  </div>
                ))}
              </div>
            </div>
  
            {/* Pestañas de navegación */}
            <div className="tabs">
              <button onClick={() => handleTabClick('caracteristicas')}>Otras Características</button>
              <button onClick={() => handleTabClick('accesorios')}>Accesorios Recomendados</button>
              <button onClick={() => handleTabClick('datasheet')}>Datasheet</button>
            </div>
  
            {/* Contenido dependiendo de la pestaña seleccionada */}
            <div className='content'>
              {selectedTab === 'caracteristicas' && (
                <div className='otras-caracteristicas'>
                  <h2>Otras Características</h2>
                  <table className="caracteristicas-tabla">
                    <tbody>
                      <tr>
                        <td>Memoria de trabajo</td>
                        <td>2 GB RAM</td>
                      </tr>
                      <tr>
                        <td>Connection slots</td>
                        <td>2x LAN: RJ45-Female, 8-pin, etc.</td>
                      </tr>
                      <tr>
                        <td>Dimension</td>
                        <td>101 x 126 x 50 mm</td>
                      </tr>
                      <tr>
                        <td>Almacenamiento de datos</td>
                        <td>8 GB EMMC</td>
                      </tr>
                      <tr>
                        <td>Procesador</td>
                        <td>1.8 GHz / 400MHz</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
  
              {selectedTab === 'accesorios' && (
                <div className='accesorios_recomendados'>
                  <h2>Accesorios Recomendados</h2>
                  {selectedAccesorio ? (
                    <div className='accesorio-detalles'>
                      <h3>{selectedAccesorio.name}</h3>
                      {/* Table with details */}
                      <table className='accesorios-tabla'>
                        <thead>
                          <tr>
                            {selectedAccesorio.id === 1 && (
                              <>
                                <th>Model</th>
                                <th>Digital inputs</th>
                                <th>Digital outputs</th>
                                <th>Auxiliary interfaces</th>
                                <th>Port-class</th>
                              </>
                            )}
                            {selectedAccesorio.id === 2 && (
                              <>
                                <th>Model</th>
                                <th>Cable</th>
                                <th>Connection 1</th>
                                <th>Connection 2</th>
                                <th>Approval/Conformity</th>
                                <th>IP rating</th>
                              </>
                            )}
                            {selectedAccesorio.id === 3 && (
                              <>
                                <th>Model</th>
                                <th>Function</th>
                                <th>Interface</th>
                                <th>IP rating</th>
                                <th>Housing material primary</th>
                                <th>Connection</th>
                              </>
                            )}
                            {selectedAccesorio.id === 4 && (
                              <>
                                <th>Model</th>
                                <th>Mounting part</th>
                                <th>Rated output current</th>
                                <th>Rated output voltage DC</th>
                                <th>IP rating</th>
                                <th>Connection</th>
                              </>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {selectedAccesorio.id === 1 && (
                            <>
                              <tr>
                                <td>
                                  <div className='accesorio-url'>
                                  <a href='/BN100HM' rel="noopener noreferrer">
                                    <img src={BNI00HM} alt="Model 1" />
                                  </a>
                                  <div className='accesorio-url-text'>
                                    <h4>BNI00HM</h4>
                                    <h6>BNI EIP-508-005-Z015-013</h6>
                                  </div>
                                  </div>
                                </td>
                                <td>16x PNP, Type 3</td>
                                <td>16x PNP</td>
                                <td>8x IO-Link</td>
                                <td>Type A</td>
                              </tr>
                              <tr>
                                <td>
                                  <div className='accesorio-url'>
                                  <a href='/BNI006A' rel="noopener noreferrer">
                                    <img src={BNI006A} alt="Model 1" />
                                  </a>
                                  <div className='accesorio-url-text'>
                                    <h4>BNI006A</h4>
                                    <h6>BNI EIP-508-105-Z015</h6>
                                  </div>
                                  </div>
                                </td>
                                <td>16x PNP, Type 3</td>
                                <td>16x PNP</td>
                                <td>8x IO-Link</td>
                                <td>Type A</td>
                              </tr>
                              <tr>
                                <td>
                                  <div className='accesorio-url'>
                                  <a href='/BNI009T' rel="noopener noreferrer">
                                    <img src={BNI009T} alt="Model 1" />
                                  </a>
                                  <div className='accesorio-url-text'>
                                    <h4>BNI009T</h4>
                                    <h6>BNI EIP-507-005-Z040</h6>
                                  </div>
                                  </div>
                                </td>
                                <td>8x PNP, Type 3</td>
                                <td>8x PNP</td>
                                <td>4x IO-Link</td>
                                <td>Type A</td>
                              </tr>
                              <tr>
                                <td>
                                  <div className='accesorio-url'>
                                  <a href='/BNI00H7' rel="noopener noreferrer">
                                    <img src={BNI00H7} alt="Model 1" />
                                  </a>
                                  <div className='accesorio-url-text'>
                                    <h4>BNI00H7</h4>
                                    <h6>BNI EIP-508-105-Z067</h6>
                                  </div>
                                  </div>
                                </td>
                                <td>16x PNP, Type 3</td>
                                <td>16x PNP</td>
                                <td>8x IO-Link</td>
                                <td>Type A</td>
                              </tr>
                            </>
                          )}
                          {selectedAccesorio.id === 2 && (
                            <>
                              <tr>
                                <td>
                                  <div className='accesorio-url'>
                                  <a href='/BCC03RM' rel="noopener noreferrer">
                                    <img src={BCC03RM} alt="Model 1" />
                                  </a>
                                  <div className='accesorio-url-text'>
                                    <h4>BCC03RM</h4>
                                    <h6>BCC M415-M314-3F-304-PX0434-050</h6>
                                  </div>
                                  </div>
                                </td>
                                <td>PUR black, 5 m, Drag chain compatible</td>
                                <td>M12x1-Female, straight, 5-pin, A-coded</td>
                                <td>M8x1-Male, straight, 4-pin, A-coded</td>
                                <td>CE, cULus, WEEE</td>
                                <td>IP67, IP68, IP69K/IP67, IP69K</td>
                              </tr>
                              <tr>
                                <td>
                                  <div className='accesorio-url'>
                                  <a href='/BCC03RK' rel="noopener noreferrer">
                                    <img src={BCC03RK} alt="Model 1" />
                                  </a>
                                  <div className='accesorio-url-text'>
                                    <h4>BCC03RK</h4>
                                    <h6>BCC M415-M314-3F-304-PX0434-020</h6>
                                  </div>
                                  </div>
                                </td>
                                <td>PUR black, 2 m, Drag chain compatible</td>
                                <td>M12x1-Female, straight, 5-pin, A-coded</td>
                                <td>M8x1-Male, straight, 4-pin, A-coded</td>
                                <td>CE, cULus, WEEE</td>
                                <td>IP67, IP68, IP69K/IP67, IP69K</td>
                              </tr>
                              <tr>
                                <td>
                                <div className='accesorio-url'>
                                  <a href='/BCC0HZF' rel="noopener noreferrer">
                                    <img src={BCC0HZF} alt="Model 1" />
                                  </a>
                                  <div className='accesorio-url-text'>
                                    <h4>BCC0HZF</h4>
                                    <h6>BCC E818-E818-90-377-PS58N9-050</h6>
                                  </div>
                                  </div>
                                </td>
                                <td>PUR Shielded green, 5 m</td>
                                <td>RJ45-Male, straight, 8-pin</td>
                                <td>RJ45-Male, straight, 8-pin</td>
                                <td>CE, cULus, WEEE</td>
                                <td>IP20/IP20</td>
                              </tr>
                              <tr>
                                <td>
                                <div className='accesorio-url'>
                                  <a href='/BCC0HZE' rel="noopener noreferrer">
                                    <img src={BCC0HZE} alt="Model 1" />
                                  </a>
                                  <div className='accesorio-url-text'>
                                    <h4>BCC0HZE</h4>
                                    <h6>BCC E818-E818-90-377-PS58N9-020</h6>
                                  </div>
                                  </div>
                                </td>
                                <td>PUR Shielded green, 2 m</td>
                                <td>RJ45-Male, straight, 8-pin</td>
                                <td>RJ45-Male, straight, 8-pin</td>
                                <td>CE, cULus, WEEE</td>
                                <td>IP20/IP20</td>
                              </tr>
                            </>
                          )}
                          {selectedAccesorio.id === 3 && (
                            <>
                              <tr>
                                <td>
                                <div className='accesorio-url'>
                                  <a href='/BCM0001' rel="noopener noreferrer">
                                    <img src={BCM00011} alt="Model 1" />
                                  </a>
                                  <div className='accesorio-url-text'>
                                    <h4>BCM0001</h4>
                                    <h6>BCM R15E-001-DI00-01,5-S4</h6>
                                  </div>
                                  </div>
                                </td>
                                <td>Vibration analysis in time domain, Contact temperature monitoring</td>
                                <td>IO-Link</td>
                                <td>IP68, IP67, IP69K</td>
                                <td>1.4404 stainless steel</td>
                                <td>Cable M12x1</td>
                              </tr>
                              <tr>
                              <td>
                                <div className='accesorio-url'>
                                  <a href='/BCM0002' rel="noopener noreferrer">
                                    <img src={BCM0002} alt="Model 1" />
                                  </a>
                                  <div className='accesorio-url-text'>
                                    <h4>BCM0002</h4>
                                    <h6>BCM R15E-001-DI00-02,5-S4</h6>
                                  </div>
                                  </div>
                                </td>
                                <td>Vibration analysis in time domain, Temperature monitoring</td>
                                <td>IO-Link</td>
                                <td>IP68, IP67, IP69K</td>
                                <td>1.4404 stainless steel</td>
                                <td>Cable M12x1</td>
                              </tr>
                              <tr>
                              <td>
                                <div className='accesorio-url'>
                                  <a href='/BCM0003' rel="noopener noreferrer">
                                    <img src={BCM0003} alt="Model 1" />
                                  </a>
                                  <div className='accesorio-url-text'>
                                    <h4>BCM0003</h4>
                                    <h6>BCM R15E-001-DI00-03,5-S4</h6>
                                  </div>
                                  </div>
                                </td>
                                <td>Vibration analysis in time domain, Contact temperature monitoring</td>
                                <td>IO-Link</td>
                                <td>IP68, IP67, IP69K</td>
                                <td>1.4404 stainless steel</td>
                                <td>Cable M12x1</td>
                              </tr>
                              <tr>
                              <td>
                                <div className='accesorio-url'>
                                  <a href='/BCM0004' rel="noopener noreferrer">
                                    <img src={BCM0004} alt="Model 1" />
                                  </a>
                                  <div className='accesorio-url-text'>
                                    <h4>BCM0004</h4>
                                    <h6>BCM R15E-001-DI00-04,5-S4</h6>
                                  </div>
                                  </div>
                                </td>
                                <td>Vibration analysis in time domain, Temperature monitoring</td>
                                <td>IO-Link</td>
                                <td>IP68, IP67, IP69K</td>
                                <td>1.4404 stainless steel</td>
                                <td>Cable M12x1</td>
                              </tr>
                            </>
                          )}
                          {selectedAccesorio.id === 4 && (
                            <>
                              <tr>
                              <td>
                                <div className='accesorio-url'>
                                  <a href='/BAE0111' rel="noopener noreferrer">
                                    <img src={BAE0111} alt="Model 1" />
                                  </a>
                                  <div className='accesorio-url-text'>
                                    <h4>BAE0111</h4>
                                    <h6>BCM R15E-001-DI00-04,5-S4</h6>
                                  </div>
                                  </div>
                                </td>
                                <td>DIN rail mounting</td>
                                <td>3.12 A</td>
                                <td>24 V</td>
                                <td>IP20</td>
                                <td>Terminal, 0.25...2.5mm2</td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <p>Selecciona un accesorio para ver detalles.</p>
                  )}
                </div>
              )}
  
              {selectedTab === 'datasheet' && (
                <div className='datasheet'>
                  <h2>Datasheet</h2>
                  <iframe
                    title="Datasheet"
                    src="https://example.com/datasheet.pdf"
                    width="100%"
                    height="600px"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  
}

export default Producto1;
