import '../../Styles/footer.css';

export default function Footer () {
  return (
    <>
      <footer className="footer">
        <div className="text-start px-3">
          &copy; EAE Insights Data & Iot S. De R.L De C.V.
        </div>
      </footer>
    </>
  );
}
