import '../Styles/CallToAction.css';
import Navbar from './Shared/Navbar.js'
import ContactUs from './Shared/contactUs.js'
import Footer from './Shared/footer.js'
import Beneficios1 from '../Components/Benefits/Beneficios1.js';


function BenefitsPages1() {
  return (
    <>
      <Navbar />

      <Beneficios1 />

      <ContactUs />
      <Footer />
    </>
  );
}

export default  BenefitsPages1;
