import '../../Styles/BeneficiosPage.css';
import Logo from "../../images/IOT-4.png"

function Beneficios2() {
  return (
    <>

<div className="Beneficios1">
        <div id='box' className="row my-5 pt-5 px-2 justify-content-center">
          <div className="col col-12 col-md-6">
            <div className='principal-box'>
              <img src={Logo} alt="IIot" />
              <h1 className="text-center">Beneficios2 Page</h1>
              <p>
                The Internet of Things (IoT) refers to the network of interconnected devices and objects that collect, exchange, and act on data using embedded sensors, software, and other technologies. These devices range from everyday household items like refrigerators and thermostats to industrial machines and infrastructure components. By integrating these devices with internet connectivity, IoT enables them to communicate and share data with each other and centralized systems, facilitating automation and smarter decision-making.
              </p>
            </div>
            <div className='secondary-box'>
              <img src={Logo} alt="IIot" />
              <p>
                One of the core advantages of IoT is its ability to provide real-time insights and efficiencies across various sectors. In the consumer space, IoT enhances convenience and improves quality of life through smart homes, where devices can be controlled remotely and work in unison to optimize energy usage, enhance security, and provide personalized experiences. In industrial settings, IoT applications include predictive maintenance of machinery, efficient supply chain management, and the optimization of production processes, leading to reduced costs and increased productivity.
              </p>
              <br></br>
              <img src={Logo} alt="IIot" />
              <p>
                However, the proliferation of IoT devices also brings challenges, particularly concerning security and privacy. As more devices connect to the internet, they become potential targets for cyberattacks, raising the need for robust security measures to protect sensitive data. Additionally, the vast amount of data generated by IoT devices necessitates effective data management strategies to ensure that the data is used responsibly and ethically. Despite these challenges, the IoT continues to grow and evolve, promising significant advancements in how we live and work.
              </p>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default Beneficios2;
